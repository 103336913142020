import api from '@/api/modules/projects';
import { STATUS_INACTIVE, STATUS_ARCHIVE, STATUS_BLOCKED } from '@/models/model';
import { Project } from '@/models/project';
import { CImage } from '@/models/image';
import { Resource } from '@/models/resource';
import { Model } from '@/models/model';
import { Money} from '@/models/money';
import * as store from '../store';
import _ from 'lodash';
import { Metric, prepareMetricList } from '@/models/metric';
import { prepareMetricUtmList } from '@/models/utm';
import { Promotion } from '@/models/promotion';
import { PromoCode } from '@/models/promoCode';
import { Activity } from '@/models/activity';
import { Bot } from '@/models/bot';

let subSteps = {0: false, 1: false, 2: false, 3: false};

const state = _.defaults({
	items				: [],
	itemsArchived		: [],
	itemsBlocked		: [],
	itemsInActive		: [],
	itemsShort			: [],
	totalItems 			: null,
	totalItemsArchived	: null,
	totalItemsBlocked	: null,
	totalItemsInActive	: null,
	totalLastActivity	: null,
	opened 				: null,
	fromCreatePage		: null,
	lastOpenedProjectId	: null,
	metrics				: [],
	metricsUtm			: [],
	promotions			: [],
	promocodes			: [],
	totalMetrics		: null,
	totalMetricsUtm		: null,
	error		: '',
	statistics 	: null,
	resources 	: null,
	activity 	: null,
	lastActivity: null,
	qrPaymentPage: null,
	wizard: {
		step				: 0,
		subStep				: _.clone(subSteps),
		resourceType 		: null
	},
	isDescriptionExpanded: false,
	GETTING_PROJECT: false,
	bot: null,
	canBeDeleted: false,
	canBeDeletedPlans: []
}, store.state);

const getters = _.defaults({
	totalItems			: state => state.totalItems,
	totalItemsArchived	: state => state.totalItemsArchived,
	totalItemsBlocked	: state => state.totalItemsBlocked,
	totalItemsInActive	: state => state.totalItemsInActive,
	totalLastActivity	: state => state.totalLastActivity,
	error				: state => state.error,
	items				: state => state.items,
	itemsArchived		: state => state.itemsArchived,
	itemsBlocked		: state => state.itemsBlocked,
	itemsInActive		: state => state.itemsInActive,
	itemsShort			: state => state.itemsShort,
	opened				: state => state.opened,
	fromCreatePage		: state => state.fromCreatePage,
	promotions			: state => state.promotions,
	promocodes			: state => state.promocodes,
	lastOpenedProjectId	: state => state.lastOpenedProjectId,
	statistics			: state => state.statistics,
	qrPaymentPage		: state => state.qrPaymentPage,
	metrics				: state => state.metrics,
	metricsUtm			: state => state.metricsUtm,
	resources			: state => state.resources,
	activity			: state => state.activity,
	lastActivity		: state => state.lastActivity,
	totalMetrics		: state => state.totalMetrics,
	totalMetricsUtm		: state => state.totalMetricsUtm,
	wizardStep			: state => state.wizard.step,
	wizardSubStep		: state => state.wizard.subStep[state.wizard.step],
	wizardResourceType	: state => state.wizard.resourceType,
	isDescriptionExpanded: state => state.isDescriptionExpanded,
	GETTING_PROJECT 	: state => state.GETTING_PROJECT,
	bot					: state => state.bot,
	canBeDeleted		: state => state.canBeDeleted,
	canBeDeletedPlans	: state => state.canBeDeletedPlans
}, store.getters);

const actions = {
	async items ({state, commit}, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.items(data);
			commit('setItems', res.data['hydra:member']);
			commit('setTotal', res.data['hydra:totalItems']);
			commit(store.PENDING, false);
			return state.items;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async shortList ({state, commit}, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.shortList(data);
			commit('setItemsShort', res.data.items);
			commit('setTotal', res.data.totalItems);
			commit(store.PENDING, false);
			return state.itemsShort;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async hash ({state, commit}, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.hash(data);
			commit(store.PENDING, false);

			if ( res.data && res.data.id ) {
				res.data.facebookPixel 		= res.data.analytics.fb;
				res.data.googleAnalytics 	= res.data.analytics.ga;
				res.data.yandexMetrics 		= res.data.analytics.ym;
				res.data.user 				= res.data.user.id;
			}

			return res.data.id ? new Project(res.data) : res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async archivedItems ({state, commit}, data) {
		data.status = STATUS_ARCHIVE;

		commit(store.START_REQUEST);

		try {
			let res = await api.items(data);
			commit('setItemsArchived', res.data['hydra:member']);
			commit('setTotalArchived', res.data['hydra:totalItems']);
			commit(store.PENDING, false);
			return state.itemsArchived;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async blockedItems ({state, commit}, data) {
		data.status = STATUS_BLOCKED;

		commit(store.START_REQUEST);

		try {
			let res = await api.items(data);
			commit('setItemsBlocked', res.data['hydra:member']);
			commit('setTotalBlocked', res.data['hydra:totalItems']);
			commit(store.PENDING, false);
			return state.itemsBlocked;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async inactiveItems ({state, commit}, data) {
		data.status = STATUS_INACTIVE;

		commit(store.START_REQUEST);

		try {
			let res = await api.items(data);
			commit('setItemsInActive', res.data['hydra:member']);
			commit('setTotalInActive', res.data['hydra:totalItems']);
			commit(store.PENDING, false);
			return state.itemsInActive;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async covers ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.covers(data);

			commit(store.PENDING, false);
			return _.map(res.data['hydra:member'], d => new CImage(d));
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			commit('GETTING_PROJECT', false);
			return Promise.reject( store.parseError(err) );
		}
	},

	async id ({state, commit}, data) {
		commit(store.START_REQUEST);
		commit('GETTING_PROJECT', true);

		try {
			let res = await api.id(data.id);
			commit('setAddBot', null);
			commit('setOpened', _.cloneDeep(res.data));

			if (res.data.bot)
				commit('setAddBot', _.cloneDeep(new Bot(res.data.bot)));

			commit('GETTING_PROJECT', false);
			commit(store.PENDING, false);
			return new Project(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			commit('GETTING_PROJECT', false);
			return Promise.reject( store.parseError(err) );
		}
	},

	async add ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.add(data);
			commit('setOpened', res.data);
			commit(store.PENDING, false);
			return new Project(res.data);
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async addNeuro ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.addNeuro(data);
			commit('setOpened', res.data);
			commit(store.PENDING, false);
			return new Project(res.data);
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async edit ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.editProject(data);
			commit('setOpened', _.cloneDeep(res.data));
			commit(store.PENDING, false);
			return new Project(res.data);
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async activate ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.activate(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async archive ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.archive(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async remove ({ commit, getters }, data ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.remove(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async statistics ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.statistics(data);
			commit('setStatistics', res.data);
			commit(store.PENDING, false);
			return res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async qrPaymentPage ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.qrPaymentPage(data);
			commit(store.PENDING, false);
			return new Promise((resolve, _) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(new Blob([res.data]));
			});
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async activity ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.activity(data);
			commit('setActivity', res.data);
			commit(store.PENDING, false);
			return res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async lastActivity ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.lastActivity(data);

			commit('setLastActivity', res.data.items);
			commit('setLastActivityTotal', res.data.totalItems);

			commit(store.PENDING, false);
			return state.lastActivity;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async promotions ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.promotions(data);
			commit('setPromotions', res.data['hydra:member']);
			commit(store.PENDING, false);
			return state.promotions;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async editPromotion ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.editPromotion(data);
			let p = new Promotion(res.data);
			commit('editPromotion', _.cloneDeep(p));
			commit(store.PENDING, false);
			return p;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async addPromotion ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.addPromotion(data);
			commit('addPromotion', _.cloneDeep(res.data));
			commit(store.PENDING, false);
			return new Promotion(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async removePromotion ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.removePromotion(data);
			commit('removePromotion', data);
			commit(store.PENDING, false);
			return true;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async archivePromotion ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.archivePromotion(data);
			commit('archivePromotion', data);
			commit(store.PENDING, false);
			return true;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async isEditablePromotions ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.isEditablePromotions(data);
			commit('setEditablePromotions', res.data);
			commit(store.PENDING, false);
			return true;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async isEditablePromocodes ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.isEditablePromocodes(data);
			commit('setEditablePromocodes', res.data);
			commit(store.PENDING, false);
			return true;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async activatePromotion ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.activatePromotion(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async promocodes ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.promocodes(data);
			commit('setPromocodes', res.data['hydra:member']);
			commit(store.PENDING, false);
			return state.promocodes;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async editPromocode ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.editPromocode(data);
			let p = new PromoCode(res.data);
			commit('editPromocode', _.cloneDeep(p));
			commit(store.PENDING, false);
			return p;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async addPromocode ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.addPromocode(data);
			commit('addPromocode', _.cloneDeep(res.data));
			commit(store.PENDING, false);
			return new PromoCode(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async removePromocode ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.removePromocode(data);
			commit('removePromocode', data);
			commit(store.PENDING, false);
			return true;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async archivePromocode ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.archivePromocode(data);
			commit('archivePromocode', _.cloneDeep(res.data));
			commit(store.PENDING, false);
			return new PromoCode(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async activatePromocode ({ commit, getters }, data = {} ) {
		commit(store.START_REQUEST, true);

		try {
			let res = await api.activatePromocode(data);
			commit(store.PENDING, false);
			return true;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async checkPromocode ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.checkPromocode(data);
			commit(store.PENDING, false);
			return res.data == null ? res.data : new PromoCode(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async removeImage ( {commit}, data ) {
		commit(store.START_REQUEST);

		try {
			let res = await api.removeImage(data);
			commit(store.PENDING, false);
			return null;
		} catch ( err ) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async metrics ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.metrics(data);

			if ( data.subCategory ) {
				commit(store.PENDING, false);
				return _.map(res.data.items, d => new Metric(d));
			}

			commit('setMetrics', res.data);
			commit(store.PENDING, false);
			return state.metrics;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async metricsUtm ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.metricsUtm(data);

			commit('setMetricsUtm', res.data);
			commit(store.PENDING, false);
			return state.metricsUtm;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async view ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.view(data);
			return new Model(res.data);
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async canBeDeleted ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.canBeDeleted(data);
			commit('setCanBeDeleted', res.data.isCanBeDeleted);
			commit('setCanBeDeletedPlans', res.data.plans);
			return res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
			commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async attachAccount ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.attachAccount(data);
			return res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async testWebhooks ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.pixelTest(data);
			commit(store.PENDING, false);
			return res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async testFBPixel ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.fbPixelTest(data);
			commit(store.PENDING, false);
			return res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	},

	async testYAMetric ({state, commit}, data) {
		commit(store.START_REQUEST);

		try {
			let res = await api.yaMetricTest(data);
			commit(store.PENDING, false);
			return res.data;
		} catch (err) {
			commit(store.FAILURE, err);
			commit(store.PENDING, false);
			store.logError(err);
commit('error', store.parseError(err));
			return Promise.reject( store.parseError(err) );
		}
	}

}

const mutations = _.defaults({
	setLastOpenedProjectId (state, i) {
		state.lastOpenedProjectId = i;
	},

	setOpened (state, i) {
		if ( i == null ) {
			state.opened 	= null;
			state.resources = null;
			return;
		}

		state.opened    = i instanceof Project ? i : new Project(i);
		state.resources = _.map( [...state.opened.channels, ...state.opened.groups], p => p instanceof Resource ? p : new Resource(p));
	},

	setFromCreatePage ( state, v) {
		state.fromCreatePage = v;
	},

	setItems ( state, list) {
		state.items = _.map(list, l => new Project(l));
	},

	setItemsShort ( state, list) {
		if ( list == null || list == null ) state.itemsShort = [];
		state.itemsShort = _.map(list, l => new Project(l));
	},

	setItemsArchived ( state, list) {
		state.itemsArchived = _.map(list, l => new Project(l));
	},

	setItemsBlocked ( state, list) {
		state.itemsBlocked = _.map(list, l => new Project(l));
	},

	setItemsInActive ( state, list) {
		state.itemsInActive = _.map(list, l => new Project(l));
	},

	setTotal ( state, i ) {
		state.totalItems = i;
	},

	setTotalArchived ( state, i ) {
		state.totalItemsArchived = i;
	},

	setTotalBlocked ( state, i ) {
		state.totalItemsBlocked = i;
	},

	setTotalInActive ( state, i ) {
		state.totalItemsInActive = i;
	},

	setStatistics ( state, items ) {
		if ( items == null ) {
			state.statistics 	= items;
			return;
		}

		if ( items.income && items.income.other ) {
			_.each(items.income.other.monthly, (m, i) => {
				items.income.other.monthly[i] = new Money(m);
			});
			_.each(items.income.other.weekly, (m, i) => {
				items.income.other.weekly[i] = new Money(m);
			});
		}

		if ( items.income && items.income.world2ru ) {
			_.each(items.income.world2ru.monthly, (m, i) => {
				items.income.world2ru.monthly[i] = new Money(m);
			});
			_.each(items.income.world2ru.weekly, (m, i) => {
				items.income.world2ru.weekly[i] = new Money(m);
			});
		}

		if ( items.mrr ) {
			if ( items.mrr.length == 0 )
				items.mrr = [
					{amount: '0', currency: 'RUB'},
					{amount: '0', currency: 'USD'},
					{amount: '0', currency: 'EUR'},
				]

			_.each(items.mrr, (m, i) => {
				items.mrr[i] = new Money(m);
			});
		}

		state.statistics = items;
	},

	setQrPaymentPage( state, items ) {
		state.qrPaymentPage = items;
	},

	setActivity ( state, items ) {
		state.activity = items;
	},

	setLastActivity ( state, items ) {
		state.lastActivity = _.map(items, i => i instanceof Activity ? i : new Activity(i));
	},

	setLastActivityTotal ( state, items ) {
		state.totalLastActivity = items;
	},

	setPromotions ( state, items ) {
		if ( state.opened ) {
			state.opened.setPromotions(items);
		}

		state.promotions = _.map(items, i => i instanceof Promotion ? i : new Promotion(i));
	},

	addPromotion ( state, item ) {
		item.isEditable = true;
		if ( state.opened ) {
			state.opened.addPromotion(item);
		}

		state.promotions.push(item instanceof Promotion ? item : new Promotion(item));
	},

	editPromotion ( state, item ) {
		if ( state.opened ) {
			state.opened.editPromotion(item);
		}

		let index = _.findIndex(state.promotions, (i) => i.id == item.id);
		if ( index == - 1  || state.promotions.length <= index ) return;
		item.isEditable = state.promotions[index].canBeDeleted;

		state.promotions[index] = item instanceof Promotion ? item : new Promotion(item);
		state.promotions = _.cloneDeep(state.promotions);
	},

	removePromotion ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.removePromotion(item);

		_.remove(state.promotions, v => v.id == item.id);
		state.promotions = _.cloneDeep(state.promotions);
	},

	archivePromotion ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.archivePromotion(item);

		let index = _.findIndex(state.promotions, (i) => i.id == item.id);
		if ( index == - 1  || state.promotions.length <= index ) return;

		state.promotions[index].setArchive(true);
		state.promotions = _.cloneDeep(state.promotions);
	},

	setEditablePromotions(state, items) {
		if ( state.opened ) {
			state.opened.setPromotionsEditable(items);
		}

		_.each(state.promotions, p => p.setEditable(_.find(items, i => i.id == p.id)));
	},

	setEditablePromocodes(state, items) {
		if ( state.opened ) {
			state.opened.setPromocodesEditable(items);
		}
		_.each(state.promocodes, p => p.setEditable(_.find(items, i => i.id == p.id)))
	},

	setPromocodes ( state, items ) {
		if ( state.opened ) {
			state.opened.setPromocodes(items);
		}

		state.promocodes = _.map(items, i => i instanceof PromoCode ? i : new PromoCode(i));
	},

	addPromocode ( state, item ) {
		if ( state.opened ) {
			state.opened.addPromocode(item);
		}

		state.promocodes.push(item instanceof PromoCode ? item : new PromoCode(item));
	},

	editPromocode ( state, item ) {
		if ( state.opened ) {
			state.opened.editPromocode(item);
		}

		let index = _.findIndex(state.promocodes, (i) => i.id == item.id);
		if ( index == - 1  || state.promocodes.length <= index ) return;

		state.promocodes[index] = item instanceof PromoCode ? item : new PromoCode(item);
		state.promocodes = _.cloneDeep(state.promocodes);
	},

	removePromocode ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.removePromocode(item);

		_.remove(state.promocodes, v => v.id == item.id);
		state.promocodes = _.cloneDeep(state.promocodes);
	},

	archivePromocode ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.archivePromocode(item);

		let index = _.findIndex(state.promocodes, (i) => i.id == item.id);
		if ( index == - 1  || state.promocodes.length <= index ) return;

		state.promocodes[index].setArchive(true);
		state.promocodes = _.cloneDeep(state.promocodes);
	},

	wizardSubStepNext (state) {
		state.wizard.subStep[state.wizard.step] = true;
	},

	wizardSubStepPrev (state) {
		state.wizard.subStep[state.wizard.step] = false;
	},

	wizardStepNext (state, i) {
		if ( i ) return state.wizard.step = i;

		state.wizard.step += 1;
		state.wizard.subStep = _.clone(subSteps);
	},

	wizardStepPrev (state, i) {
		if ( i ) return state.wizard.step = i;

		state.wizard.step -= 1;
		state.wizard.subStep = _.clone(subSteps);
	},

	wizardResourceAddType ( state, i ) {
		state.wizard.resourceType = i;
	},

	wizard ( state ) {
		state.wizard.step 			= 0;
		state.wizard.subStep		= _.clone(subSteps);
		state.wizard.resourceType 	= null;
	},

	editAnalytics (state, {pixel, vkAds, vkPixel, ga, ya} ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.setAnalytis({pixel, vkAds, vkPixel, ga, ya});
	},

	setPlans ( state, items ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.setPlans(items);
	},

	editPlan ( state, plan ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.editPlan(plan);

		_.each(state.items, v => v.editPlan(plan))
	},

	addPlan ( state, {plan, active} ) {
		if ( state.opened && state.opened instanceof Project ) {
			state.opened.addPlan(plan);
			if ( active ) state.opened.setArchive(false);
		}

		_.each(state.items, v => {
			v.addPlan(plan);
			if ( active ) v.setArchive(false);
		})
	},

	removePlan ( state, plan ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.removePlan(plan);

		_.each(state.items, v => v.removePlan(plan))
	},

	removeResource ( state, i ) {
		_.remove(state.opened.channels, v => v.id == i.id);
		_.remove(state.opened.groups, v => v.id == i.id);

		state.opened.channels = _.cloneDeep(state.opened.channels);
		state.opened.groups = _.cloneDeep(state.opened.groups);
	},

	setAccounts ( state, items ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.setAccounts(items);
	},

	setChannels ( state, items ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.setChannels(items);

		state.resources = _.map( items, p => p instanceof Resource ? p : new Resource(p));
	},

	setGroups ( state, items ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.setGroups(items);

		state.resources = _.map( items, p => p instanceof Resource ? p : new Resource(p));
	},

	editChannel ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.editChannel(item);

		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );
	},

	editGroup ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.editGroup(item);

		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );
	},

	addChannel ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.addChannel(item);

		let exist = _.find(state.resources, g => g.id == item.id );
		if ( exist ) return;

		state.resources.push(item instanceof Resource ? item : new Resource(item));
		state.resources = _.cloneDeep(state.resources);
	},

	addGroup ( state, item ) {
		if ( state.opened && state.opened instanceof Project ) state.opened.addGroup(item);

		let exist = _.find(state.resources, g => g.id == item.id );
		if ( exist ) return;

		state.resources.push(item instanceof Resource ? item : new Resource(item));
		state.resources = _.cloneDeep(state.resources);
	},

	editChannelForList ( state, item ) {
		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );

		_.each( state.items, i => {
			_.each( i.plans, p => {
				if ( p.id == item.plan.id )
					p.setChannel(item);
			});
		})
	},

	editGroupForList ( state, item ) {
		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );

		_.each( state.items, i => {
			_.each( i.plans, p => {
				if ( p.id == item.plan.id )
					p.setGroup(item);
			});
		})
	},

	editChannelForArchivedList ( state, item ) {
		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );

		_.each( state.itemsArchived, i => {
			_.each( i.plans, p => {
				if ( p.id == item.plan.id )
					p.setChannel(item);
			});
		})
	},

	editGroupForArchivedList ( state, item ) {
		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );

		_.each( state.itemsArchived, i => {
			_.each( i.plans, p => {
				if ( p.id == item.plan.id )
					p.setGroup(item);
			});
		})
	},

	editChannelForBlockedList ( state, item ) {
		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );

		_.each( state.itemsBlocked, i => {
			_.each( i.plans, p => {
				if ( p.id == item.plan.id )
					p.setChannel(item);
			});
		})
	},

	editGroupForBlockedList ( state, item ) {
		state.resources = _.map( state.resources, p => p.id == item.id ? (item instanceof Resource ? item : new Resource(item)) : p );

		_.each( state.itemsBlocked, i => {
			_.each( i.plans, p => {
				if ( p.id == item.plan.id )
					p.setGroup(item);
			});
		})
	},

	archive ( state, item ) {
		let index = _.findIndex(state.items, (i) => i.id == item.id);

		if ( state.opened && state.opened instanceof Project ) state.opened.setArchive();

		if ( index == - 1  || state.items.length <= index ) return;

		state.items[index].setArchive();
	},

	remove ( state, item ) {
		let index = _.findIndex(state.items, (i) => i.id == item.id);

		if ( state.opened && state.opened instanceof Project ) state.opened = null;
		if ( state.bot && state.bot instanceof Bot ) state.bot = null;

		if ( index == - 1  || state.items.length <= index ) return;

		state.items.splice(index, 1);
	},

	activate ( state, id ) {
		if ( id ) {
			_.each( state.items, i => {
				if ( i.id == id )
					i.setArchive(false);
			});
		}

		if ( state.opened && state.opened instanceof Project ) state.opened.setArchive(false);
	},

	updateResource ( state, item ) {
		if ( state.opened ) {
			if ( item.isGroup() ) state.opened.editGroup( item )
			if ( item.isChannel() ) state.opened.editChannel( item )

			state.opened = _.cloneDeep(state.opened)
		}
	},

	setMetricsItems ( state, items ) {
		state.metrics.items = items;
	},

	setMetrics ( state, items ) {
		state.metrics = prepareMetricList(items);
	},

	setMetricsUtmItems ( state, items ) {
		state.metricsUtm.items = items;
	},

	setMetricsUtm ( state, items ) {
		state.metricsUtm = prepareMetricUtmList(items);
	},

	metricExpand ( state, item ) {
		item.isExtended = !item.isExtended;
		let i = _.findIndex( state.metrics.items, a => a.categoryType == item.categoryType );

		if (i != -1) {
			if ( item.isExtended ) {
				state.metrics.items = _.cloneDeep([...state.metrics.items.slice(0, i+1), ...item.subCategory, ...state.metrics.items.slice(i+1)])
			} else {
				state.metrics.items = _.filter(state.metrics.items, s => s.parent != item.categoryType)
			}
		}
	},
	metricUtmExpand ( state, item ) {
		item.setExtended()
		let i = _.findIndex( state.metricsUtm.items, a => a.key == item.key );

		if (i != -1) {
			if ( item.isExtended ) {
				state.metricsUtm.items = _.cloneDeep([...state.metricsUtm.items.slice(0, i+1), ...item.children, ...state.metricsUtm.items.slice(i+1)])
			} else {
				let keys = _.flattenDeep(item.getChildrenKeys());
				state.metricsUtm.items = _.filter(state.metricsUtm.items, s => !keys.includes(s.key))
			}
		}
	},

	setDescriptionExpanded ( state, v ) {
		state.isDescriptionExpanded = v;
	},

	GETTING_PROJECT ( state, value ) {
		state.GETTING_PROJECT = value;
	},

	setAddBot(state, item) {
		state.bot = item;
	},

	setCanBeDeleted(state, item) {
		state.canBeDeleted = item;
	},

	setCanBeDeletedPlans(state, item) {
		state.canBeDeletedPlans = item;
	}
}, store.mutations);

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
