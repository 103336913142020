import { Model } from './model';
import { Plan } from './plan';
import { Bot } from './bot';
import { Merchant } from './merchant';
import { Resource } from './resource';
import { CImage } from './image';
import { Account } from './account';
import { _ } from 'core-js';
import moment from 'moment';
import { DOMAIN } from '@/api';
import { Promotion } from './promotion';
import { PromoCode } from './promoCode';

export class Project extends Model {
	constructor ( data ) {
		super(data);
		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			accounts			: data.accounts ? _.map(data.accounts, a => a instanceof Account ? a : new Account(a)) : data.accounts,
			type				: data.type,
			bot					: data.bot ? (data.bot instanceof Bot ? data.bot : new Bot(data.bot)) : null,
			botImage			: data.bot && data.bot.image ? (data.bot.image instanceof CImage ? data.bot.image : new CImage(data.bot.image)) : null,
			title				: data.title,
			description 		: data.description,
			social 				: data.social,
			image 				: data.image ? (data.image instanceof CImage ? data.image : new CImage(data.image)) : null,
			plans 				: data.plans != null ? _.map( data.plans, p => new Plan(p)) : [],
			status 				: data.status,
			hashId 				: data.hashId,
			hashIdCustom		: data.hashIdCustom ? data.hashIdCustom : data.hashId,
			channels 			: data.channels != null ? _.map( data.channels, p => _.isString(p) ? new Resource({'@id': p}) : new Resource(p)) : [],
			groups				: data.groups != null ? _.map( data.groups, p => _.isString(p) ? new Resource({'@id': p}) : new Resource(p)) : [],
			link 				: data.paymentLink,
			user 				: data.user != null ? (_.isString(data.user) ? new Merchant({'@id' : data.user}) : new Merchant(data.user)) : data.user,
			pixel 				: new PixelFB( data.facebookPixel ),
			vkPixel 			: data.vkPixel,
			vkAds 				: data.vkAds,
			pixel2 				: data.pixel,
			googleAnalytics 	: data.googleAnalytics,
			yandexMetrics 		: data.yandexMetrics,
			canBeDeleted 		: data.canBeDeleted,
			isTinkoffPayment	: data.isTinkoffPayment,
			isAllowedExtraPrice	: data.isAllowedExtraPrice,
			isModerateChats		: data.isModerateChats,
			isKickTrialsEarly	: !data.isKickTrialsEarly,
			postbackUrl 		: data.postbackUrl,
			errorStrategy 		: data.errorStrategy,
			isPaymentPageDark 	: data.is_payment_page_dark || data.isPaymentPageDark,
			isPaymentThemeDark 	: data.is_payment_page_dark,
			isPromoCodesEmpty 	: data.isPromoCodesEmpty,
			viewedAt			: data.viewedAt,
			promotions 			: [],
			promocodes 			: [],
			tags				: data.tags ? data.tags : []
		});

		this.linkHashId = `//${DOMAIN}/${this.hashIdCustom}`;
	}

	hasCover () { return this.image && this.image.contentUrl; }
	setCanBeDeleted (v) {
		this.canBeDeleted = v.isCanBeDeleted;
		_.each(this.plans, p => {
			_.each(v.plans, pp => {
				if ( p.id == pp.id ) {
					p.canBeDeleted = pp.isCanBeDeleted;
				}
			})
		})

	}

	isFIZ () {
		if ( !this.accounts || this.accounts.length == 0 ) return false;
		return _.some( this.accounts, a => a.isTinkoff() );
	}

	isOOO () {
		if ( !this.accounts || this.accounts.length == 0 ) return false;
		return _.some( this.accounts, a => a.isOrganization() );
	}

	isNeuro () {
		if ( this.type == 'PLAIN') return false;
		return true;
	}

	hasBot () {
		if ( this.bot ) return true;
		return false;
	}

	hasOtherCurrencyAccount () { return this.accounts.length > 0 && _.some(this.accounts, a => !a.isTinkoff()) }
	hasRUBAccount () { return this.accounts.length > 0 && _.some(this.accounts, a => a.isTinkoff()) }
	hasPaymentSystem() {
		return this.isOOO() || this.isFIZ();
	}
	hasMoreThanOnePlanWithoutResource () {
		let a = _.filter(this.plans, p => !p.hasResource());

		return a.length != 0 && a.length < this.plans.length;
	}

	hasPlanResources () {
		return this.plans && this.plans.length > 0 && _.every(this.plans, p => p.hasResource());
	}

	hasSomePlanWithResource () {
		return this.plans && this.plans.length > 0 && _.some(this.plans, p => p.hasResource());
	}

	hasSomeResourcesBotIssue () {
		return this.tags.includes('BOT_ISSUE') || (this.plans && this.plans.length > 0 && _.some(this.plans, p => p.hasBotIssue()));
	}

	hasSomeResourcesBotIssueNoTags () {
		return (this.plans && this.plans.length > 0 && _.some(this.plans, p => p.hasBotIssue()));
	}

	getResources () { return _.uniq([..._.map(this.plans, p => p.getResources())])}

	getPixelId () {
		return this.pixel.getId();
	}

	getVKAdsId () {
		return this.vkAds;
	}

	getVKPixelId () {
		return this.vkPixel;
	}

	static isVKAdsValid (v) {
		const REGEXP = /^\d{7}$/;
		return REGEXP.test(v);
	}

	static isVKPixelValid (v) {
		const REGEXP = /^VK\-/;
		return REGEXP.test(v);
	}

	static isGAValid (v) {
		const REGEXP = /^(GT|G|AW|UA)\-[a-zA-Z0-9\-]{9,}$/;
		return REGEXP.test(v);
	}

	static isFBValid (v) {
		const REGEXP = /^\d{15,16}$/;
		return REGEXP.test(v);
	}

	static isYAValid (v) {
		const REGEXP = /^\d{6,}$/;
		return REGEXP.test(v);
	}

	getPixelViewProject () 	{ return this.pixel.getViewProject() }
	getPixelClickPurchase (){ return this.pixel.getClickPurchase() }
	getPixelPurchase ()     { return this.pixel.getPurchase() }

	getGAId () { return this.googleAnalytics.gaId || this.googleAnalytics.id; }
	getYMId () { return this.yandexMetrics.ymId || this.yandexMetrics.id; }

	setAnalytis ({pixel, ga, ya, vkAds, vkPixel}) {
		if (typeof pixel != 'undefined')
			this.pixel = new PixelFB(pixel);
		if (typeof vkAds != 'undefined')
			this.vkAds = vkAds;
		if (typeof vkPixel != 'undefined')
			this.vkPixel = vkPixel;
		if (typeof ga != 'undefined')
			this.googleAnalytics = ga;
		if (typeof ya != 'undefined')
			this.yandexMetrics = ya;
	}

	hasPlan ( plan ) {
		return _.some(this.plans, {id: plan.id});
	}

	hasPlans () {
		return _.isArray(this.plans) && this.plans.length > 0;
	}

	editPlan ( plan ) {
		let index = _.findIndex( this.plans, p => p.id == plan.id );

		if ( index != -1 )
			this.plans[index] = plan instanceof Plan ? plan : new Plan(plan);

		this.plans = _.cloneDeep(this.plans);
	}

	removePlan ( plan ) {
		let index = _.findIndex( this.plans, p => p.id == plan.id );

		if ( index != -1 )
			this.plans.splice(index, 1);
	}

	addPlan ( item ) {
		this.plans.push(item instanceof Plan ? item : new Plan(item));
	}

	setPlans ( items ) {
		this.plans = items != null ? _.map( items, p => p instanceof Plan ? p : new Plan(p)) : [];
	}

	isAllPlansArchived () {
		if ( this.plans.length == 0 ) return false;

		return _.every(this.plans, p => p.isArchived());
	}

	setChannels ( items ) {
		this.channels = items != null ? _.map( items, p => p instanceof Resource ? p : new Resource(p)) : [];
	}

	setGroups ( items ) {
		this.groups = items != null ? _.map( items, p => p instanceof Resource ? p : new Resource(p)) : [];
	}

	editChannel ( item ) {
		item.updatedAt = moment().format('DD MMM YYYY HH:mm:ss')

		for( var i in this.channels) {
			if (this.channels[i].id == item.id) {
				this.channels[i] = item instanceof Resource ? item : new Resource(item);
			}
		}

		_.each( this.plans, p => {
			p.updateChannel(item);
		})
	}

	editGroup ( item ) {
		item.updatedAt = moment().format('DD MMM YYYY HH:mm:ss')

		for( var i in this.groups) {
			if (this.groups[i].id == item.id) {
				this.groups[i] = item instanceof Resource ? item : new Resource(item);
			}
		}

		_.each( this.plans, p => {
			p.updateGroup(item);
		})
	}

	addChannel ( item ) {
		let exist = _.find(this.channels, g => g.id == item.id );
		if ( exist ) return;

		this.channels.push(item instanceof Resource ? item : new Resource(item));
	}

	addGroup ( item ) {
		let exist = _.find(this.groups, g => g.id == item.id );
		if ( exist ) return;

		this.groups.push(item instanceof Resource ? item : new Resource(item));
	}

	setAccounts ( items ) {
		this.accounts = _.map(items, a => a instanceof Account ? a : new Account(a))
	}

	setPromotions ( items ) {
		this.promotions = _.map(items, i => i instanceof Promotion ? i : new Promotion(i))
	}

	addPromotion ( i ) {
		this.promotions.push(i instanceof Promotion ? i : new Promotion(i));
	}

	editPromotion ( i ) {
		let index = _.findIndex( this.promotions, p => p.id == i.id );

		if ( index != -1 ) {
			i.isEditable = this.promotions[index].canBeDeleted;
			i.canBeDeleted = this.promotions[index].canBeDeleted;
			this.promotions[index] = i instanceof Promotion ? i : new Promotion(i);
		}
	}

	removePromotion ( i ) {
		_.remove(this.promotions, v => v.id == i.id);
	}

	setPromotionsEditable( items ) {
		_.each(this.promotions, p => p.setEditable(_.find(items, i => i.id == p.id)))
	}

	setPromocodesEditable( items ) {
		_.each(this.promocodes, p => p.setEditable(_.find(items, i => i.id == p.id)))
	}

	archivePromotion ( item ) {
		let index = _.findIndex(this.promotions, (i) => i.id == item.id);
		if ( index == - 1  || this.promotions.length <= index ) return;

		this.promotions[index].setArchive(true);
	}

	setPromocodes ( items ) {
		this.promocodes = _.map(items, i => i instanceof PromoCode ? i : new PromoCode(i))
	}

	addPromocode ( i ) {
		this.promocodes.push(i instanceof PromoCode ? i : new PromoCode(i));
	}

	editPromocode ( i ) {
		let index = _.findIndex( this.promocodes, p => p.id == i.id );

		if ( index != -1 )
			this.promocodes[index] = i instanceof PromoCode ? i : new PromoCode(i);
	}

	removePromocode ( i ) {
		_.remove(this.promocodes, v => v.id == i.id);
	}


	archivePromocode ( item ) {
		let index = _.findIndex(this.promocodes, (i) => i.id == item.id);
		if ( index == - 1  || this.promocodes.length <= index ) return;

		this.promocodes[index].setArchive(true);
	}

	hasResources() {
		return this.channels.length > 0 || this.groups.length > 0;
	}

	hasResourceActive() {
		return _.some(this.channels, c => !c.hasBotIssue()) && _.some(this.channels, c => !c.isBotRemoved()) || _.some(this.groups, c => !c.hasBotIssue()) && _.some(this.groups, c => !c.isBotRemoved())
	}

	canSeeMenuPlans() {
		return this.hasResources() && this.hasResourceActive();
	}

	canSeeMenuItems() {
		if (!this.isNeuro()) {
			if ( (this.isActive() || this.isBlocked()) && this.plans.length > 0 && !this.isAllPlansArchived() && this.hasResources() ) return true;
			if ( this.isArchived() ) return this.hasResources() && this.hasPlans();
		}

		if (this.isNeuro()) {
			if ( (this.isActive() || this.isBlocked()) ) return true;
			if ( this.isArchived() ) return this.hasBot();
		}

		return false;
	}

	canSeeCover() {
		if (this.image && this.image.contentUrl) return true;

		return false;
	}

	somePlansHasResourceActive() {
		return _.some(_.filter(this.plans, pp => pp.isActive()), p => {
			if ( p.groups.length == 0 && (_.some(p.channels, c => c.isActive())) ) {
				return true;
			} else if ( p.groups.length == 0 && (_.some(p.channels, c => !c.isActive())) ) {
				return p.channels.length != 0 && _.some(p.channels, c => !c.hasBotIssue() && !c.isBotRemoved());
			}

			if ( p.channels.length == 0 && (_.some(p.groups, c => c.isActive())) ) {
				return true;
			} if ( p.channels.length == 0 && (_.some(p.groups, c => !c.isActive())) ) {
				return p.groups.length != 0 && _.some(p.groups, c => !c.hasBotIssue() && !c.isBotRemoved());
			}

			if (p.channels.length != 0 && p.groups.length != 0 && (_.some(p.channels, c => c.isActive()))) {
				return true;
			} else if (p.channels.length != 0 && p.groups.length != 0 && (_.some(p.groups, c => c.isActive()))) {
				return true;
			} else {
				return _.some(p.channels, c => !c.hasBotIssue() && !c.isBotRemoved()) && _.some(p.groups, c => !c.hasBotIssue() && !c.isBotRemoved());
			}
		});
	}

	/* somePlansHasResourceActive() {
		return _.some(_.filter(this.plans, pp => pp.isActive()), p => {
			if ( p.groups.length == 0 ) {
				return p.channels.length != 0 && _.some(p.channels, c => !c.hasBotIssue())
			}
			if ( p.channels.length == 0 ) {
				return p.groups.length != 0 && _.some(p.groups, c => !c.hasBotIssue())
			}

			return p.channels.length != 0 && p.groups.length != 0 && _.some(p.channels, c => !c.hasBotIssue()) && _.some(p.groups, c => !c.hasBotIssue());
		});
	} */

	isReady() {
		if ( !(this.description != null && this.description.length > 0 && this.image != null) ) return false;
		if ( !this.canSeeMenuPlans() ) return false;
		if ( !(this.plans.length > 0 && !this.isAllPlansArchived() && this.somePlansHasResourceActive()) ) return false;

		return true;
	}
}

export class PixelFB {
	constructor ( data ) {
		if ( _.isNil(data) ) { return }

		_.assignIn(this, {
			id 				: data.id,
			pixelId			: data.pixelId,
			viewProject		: data.viewProject,
			clickPurchase	: data.clickPurchase,
			purchase		: data.purchase
		});
	}

	getId () 			{ return this.pixelId || this.id }
	getViewProject () 	{ return this.viewProject }
	getClickPurchase () { return this.clickPurchase }
	getPurchase () 		{ return this.purchase }
}

export const STATUSES = ['ACTIVE', 'BLOCKED'];
export const SMART_SUB_RECOVERY = ['EXTENDED', 'SIMPLE'];
export const DENY_RUB_PAYMENTS = [];

export const PLAN_TABS = {
	stock: 'stock',
	tariff: 'tariff',
	promoCode: 'promo-code',
	trial: 'trial',
}
